@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

*, ::before, ::after{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

::backdrop{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
.container{
  width: 100%;
}
@media (min-width: 576px){

  .container{
    max-width: 576px;
  }
}
@media (min-width: 768px){

  .container{
    max-width: 768px;
  }
}
@media (min-width: 992px){

  .container{
    max-width: 992px;
  }
}
@media (min-width: 1280px){

  .container{
    max-width: 1280px;
  }
}
.sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.pointer-events-none{
  pointer-events: none;
}
.visible{
  visibility: visible;
}
.invisible{
  visibility: hidden;
}
.static{
  position: static;
}
.fixed{
  position: fixed;
}
.absolute{
  position: absolute;
}
.relative{
  position: relative;
}
.bottom-0{
  bottom: 0px;
}
.bottom-8{
  bottom: 2rem;
}
.bottom-\[2px\]{
  bottom: 2px;
}
.left-0{
  left: 0px;
}
.right-0{
  right: 0px;
}
.right-8{
  right: 2rem;
}
.start-1\/2{
  inset-inline-start: 50%;
}
.top-0{
  top: 0px;
}
.top-1\/2{
  top: 50%;
}
.top-\[-24px\]{
  top: -24px;
}
.z-10{
  z-index: 10;
}
.z-50{
  z-index: 50;
}
.z-\[1000\]{
  z-index: 1000;
}
.z-\[999\]{
  z-index: 999;
}
.col-span-2{
  grid-column: span 2 / span 2;
}
.col-span-3{
  grid-column: span 3 / span 3;
}
.float-left{
  float: left;
}
.m-0{
  margin: 0px;
}
.mx-1{
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
}
.my-0{
  margin-top: 0px;
  margin-bottom: 0px;
}
.my-12{
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-20{
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.my-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.\!mb-0{
  margin-bottom: 0px !important;
}
.\!mb-2{
  margin-bottom: 0.5rem !important;
}
.\!mb-4{
  margin-bottom: 1rem !important;
}
.\!mb-8{
  margin-bottom: 2rem !important;
}
.\!ml-0{
  margin-left: 0px !important;
}
.\!mr-1{
  margin-right: 0.25rem !important;
}
.\!mt-1{
  margin-top: 0.25rem !important;
}
.mb-0{
  margin-bottom: 0px;
}
.mb-1{
  margin-bottom: 0.25rem;
}
.mb-10{
  margin-bottom: 2.5rem;
}
.mb-12{
  margin-bottom: 3rem;
}
.mb-14{
  margin-bottom: 3.5rem;
}
.mb-16{
  margin-bottom: 4rem;
}
.mb-2{
  margin-bottom: 0.5rem;
}
.mb-3{
  margin-bottom: 0.75rem;
}
.mb-4{
  margin-bottom: 1rem;
}
.mb-5{
  margin-bottom: 1.25rem;
}
.mb-6{
  margin-bottom: 1.5rem;
}
.mb-8{
  margin-bottom: 2rem;
}
.ml-10{
  margin-left: 2.5rem;
}
.ml-12{
  margin-left: 3rem;
}
.ml-2{
  margin-left: 0.5rem;
}
.ml-3{
  margin-left: 0.75rem;
}
.ml-4{
  margin-left: 1rem;
}
.mr-1{
  margin-right: 0.25rem;
}
.mr-2{
  margin-right: 0.5rem;
}
.mr-4{
  margin-right: 1rem;
}
.mt-1{
  margin-top: 0.25rem;
}
.mt-10{
  margin-top: 2.5rem;
}
.mt-2{
  margin-top: 0.5rem;
}
.mt-3{
  margin-top: 0.75rem;
}
.mt-4{
  margin-top: 1rem;
}
.mt-6{
  margin-top: 1.5rem;
}
.mt-7{
  margin-top: 1.75rem;
}
.mt-8{
  margin-top: 2rem;
}
.mt-\[-4px\]{
  margin-top: -4px;
}
.block{
  display: block;
}
.inline{
  display: inline;
}
.flex{
  display: flex;
}
.inline-flex{
  display: inline-flex;
}
.table{
  display: table;
}
.grid{
  display: grid;
}
.\!hidden{
  display: none !important;
}
.hidden{
  display: none;
}
.size-full{
  width: 100%;
  height: 100%;
}
.h-10{
  height: 2.5rem;
}
.h-16{
  height: 4rem;
}
.h-20{
  height: 5rem;
}
.h-4{
  height: 1rem;
}
.h-5{
  height: 1.25rem;
}
.h-\[100px\]{
  height: 100px;
}
.h-\[110px\]{
  height: 110px;
}
.h-\[220px\]{
  height: 220px;
}
.h-\[50px\]{
  height: 50px;
}
.h-auto{
  height: auto;
}
.h-full{
  height: 100%;
}
.max-h-52{
  max-height: 13rem;
}
.min-h-\[200px\]{
  min-height: 200px;
}
.min-h-\[40px\]{
  min-height: 40px;
}
.w-1\/2{
  width: 50%;
}
.w-10{
  width: 2.5rem;
}
.w-20{
  width: 5rem;
}
.w-4{
  width: 1rem;
}
.w-5{
  width: 1.25rem;
}
.w-\[100px\]{
  width: 100px;
}
.w-\[200px\]{
  width: 200px;
}
.w-\[304px\]{
  width: 304px;
}
.w-\[344px\]{
  width: 344px;
}
.w-\[40px\]{
  width: 40px;
}
.w-auto{
  width: auto;
}
.w-fit{
  width: -moz-fit-content;
  width: fit-content;
}
.w-full{
  width: 100%;
}
.\!max-w-full{
  max-width: 100% !important;
}
.max-w-2xl{
  max-width: 42rem;
}
.max-w-3xl{
  max-width: 48rem;
}
.max-w-4xl{
  max-width: 56rem;
}
.max-w-5xl{
  max-width: 64rem;
}
.max-w-\[300px\]{
  max-width: 300px;
}
.max-w-\[624px\]{
  max-width: 624px;
}
.max-w-full{
  max-width: 100%;
}
.max-w-md{
  max-width: 28rem;
}
.max-w-sm{
  max-width: 24rem;
}
.max-w-xs{
  max-width: 20rem;
}
.flex-1{
  flex: 1 1 0%;
}
.shrink{
  flex-shrink: 1;
}
.shrink-0{
  flex-shrink: 0;
}
.grow{
  flex-grow: 1;
}
.basis-1\/2{
  flex-basis: 50%;
}
.basis-1\/3{
  flex-basis: 33.333333%;
}
.basis-1\/4{
  flex-basis: 25%;
}
.-translate-x-1\/2{
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2{
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-90{
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform{
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-default{
  cursor: default;
}
.cursor-pointer{
  cursor: pointer;
}
.resize{
  resize: both;
}
.list-inside{
  list-style-position: inside;
}
.list-none{
  list-style-type: none;
}
.grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-5{
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.flex-row{
  flex-direction: row;
}
.flex-row-reverse{
  flex-direction: row-reverse;
}
.flex-col{
  flex-direction: column;
}
.flex-col-reverse{
  flex-direction: column-reverse;
}
.flex-wrap{
  flex-wrap: wrap;
}
.items-start{
  align-items: flex-start;
}
.items-end{
  align-items: flex-end;
}
.items-center{
  align-items: center;
}
.items-stretch{
  align-items: stretch;
}
.justify-start{
  justify-content: flex-start;
}
.justify-end{
  justify-content: flex-end;
}
.justify-center{
  justify-content: center;
}
.justify-between{
  justify-content: space-between;
}
.justify-around{
  justify-content: space-around;
}
.gap-0{
  gap: 0px;
}
.gap-16{
  gap: 4rem;
}
.gap-2{
  gap: 0.5rem;
}
.gap-4{
  gap: 1rem;
}
.gap-5{
  gap: 1.25rem;
}
.gap-8{
  gap: 2rem;
}
.gap-x-2{
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.gap-y-2{
  row-gap: 0.5rem;
}
.gap-y-4{
  row-gap: 1rem;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.overflow-auto{
  overflow: auto;
}
.overflow-hidden{
  overflow: hidden;
}
.text-ellipsis{
  text-overflow: ellipsis;
}
.whitespace-nowrap{
  white-space: nowrap;
}
.whitespace-pre-line{
  white-space: pre-line;
}
.rounded{
  border-radius: 0.25rem;
}
.rounded-2xl{
  border-radius: 1rem;
}
.rounded-full{
  border-radius: 9999px;
}
.rounded-lg{
  border-radius: 0.5rem;
}
.rounded-sm{
  border-radius: 0.125rem;
}
.rounded-xl{
  border-radius: 0.75rem;
}
.border{
  border-width: 1px;
}
.border-0{
  border-width: 0px;
}
.border-2{
  border-width: 2px;
}
.border-\[1px\]{
  border-width: 1px;
}
.border-y-\[1px\]{
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.border-b-4{
  border-bottom-width: 4px;
}
.border-l-2{
  border-left-width: 2px;
}
.border-l-\[10px\]{
  border-left-width: 10px;
}
.border-l-\[6px\]{
  border-left-width: 6px;
}
.border-t-2{
  border-top-width: 2px;
}
.border-t-\[1px\]{
  border-top-width: 1px;
}
.border-solid{
  border-style: solid;
}
.border-dashed{
  border-style: dashed;
}
.border-black{
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}
.border-blue-300{
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}
.border-blue-500{
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity, 1));
}
.border-gray-200{
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.border-gray-300{
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}
.border-gray-400{
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}
.border-gray-500{
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity, 1));
}
.border-green-900{
  --tw-border-opacity: 1;
  border-color: rgb(20 83 45 / var(--tw-border-opacity, 1));
}
.border-scbluefrance{
  --tw-border-opacity: 1;
  border-color: rgb(0 0 145 / var(--tw-border-opacity, 1));
}
.border-scblueinfo{
  --tw-border-opacity: 1;
  border-color: rgb(0 99 203 / var(--tw-border-opacity, 1));
}
.border-scorange{
  --tw-border-opacity: 1;
  border-color: rgb(234 144 1 / var(--tw-border-opacity, 1));
}
.border-scpurplepop{
  --tw-border-opacity: 1;
  border-color: rgb(106 106 244 / var(--tw-border-opacity, 1));
}
.border-slate-500{
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}
.border-white{
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.border-b-sclightblue{
  --tw-border-opacity: 1;
  border-bottom-color: rgb(43 124 159 / var(--tw-border-opacity, 1));
}
.\!bg-green-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-white{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}
.bg-blue-100{
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}
.bg-blue-300{
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity, 1));
}
.bg-gray-100{
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.bg-gray-200{
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}
.bg-gray-300{
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}
.bg-green-100{
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}
.bg-green-700{
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
}
.bg-green-700\/50{
  background-color: rgb(21 128 61 / 0.5);
}
.bg-purple-100{
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
}
.bg-scbluefrance{
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 145 / var(--tw-bg-opacity, 1));
}
.bg-scblueinfo{
  --tw-bg-opacity: 1;
  background-color: rgb(0 99 203 / var(--tw-bg-opacity, 1));
}
.bg-sclightblueinfo{
  --tw-bg-opacity: 1;
  background-color: rgb(232 237 255 / var(--tw-bg-opacity, 1));
}
.bg-sclightgreensuccess{
  --tw-bg-opacity: 1;
  background-color: rgb(184 254 201 / var(--tw-bg-opacity, 1));
}
.bg-sclightorangewarn{
  --tw-bg-opacity: 1;
  background-color: rgb(255 225 209 / var(--tw-bg-opacity, 1));
}
.bg-sclightpurple{
  --tw-bg-opacity: 1;
  background-color: rgb(227 227 253 / var(--tw-bg-opacity, 1));
}
.bg-sclightpurpledarker{
  --tw-bg-opacity: 1;
  background-color: rgb(202 202 251 / var(--tw-bg-opacity, 1));
}
.bg-sclightrederror{
  --tw-bg-opacity: 1;
  background-color: rgb(255 233 233 / var(--tw-bg-opacity, 1));
}
.bg-scpurplepop{
  --tw-bg-opacity: 1;
  background-color: rgb(106 106 244 / var(--tw-bg-opacity, 1));
}
.bg-slate-100{
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}
.bg-slate-500{
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}
.bg-white{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-yellow-100{
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}
.bg-opacity-80{
  --tw-bg-opacity: 0.8;
}
.\!bg-none{
  background-image: none !important;
}
.bg-cover{
  background-size: cover;
}
.stroke-current{
  stroke: currentColor;
}
.\!p-0{
  padding: 0px !important;
}
.\!p-8{
  padding: 2rem !important;
}
.p-0{
  padding: 0px;
}
.p-1{
  padding: 0.25rem;
}
.p-2{
  padding: 0.5rem;
}
.p-4{
  padding: 1rem;
}
.px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-8{
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-12{
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-14{
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-20{
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-8{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.\!pl-12{
  padding-left: 3rem !important;
}
.\!pl-9{
  padding-left: 2.25rem !important;
}
.\!pr-4{
  padding-right: 1rem !important;
}
.pb-2{
  padding-bottom: 0.5rem;
}
.pb-4{
  padding-bottom: 1rem;
}
.pb-6{
  padding-bottom: 1.5rem;
}
.pb-8{
  padding-bottom: 2rem;
}
.pl-0{
  padding-left: 0px;
}
.pl-1{
  padding-left: 0.25rem;
}
.pl-2{
  padding-left: 0.5rem;
}
.pl-4{
  padding-left: 1rem;
}
.pl-6{
  padding-left: 1.5rem;
}
.pl-8{
  padding-left: 2rem;
}
.pr-2{
  padding-right: 0.5rem;
}
.pt-10{
  padding-top: 2.5rem;
}
.pt-2{
  padding-top: 0.5rem;
}
.pt-4{
  padding-top: 1rem;
}
.pt-6{
  padding-top: 1.5rem;
}
.pt-8{
  padding-top: 2rem;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.align-middle{
  vertical-align: middle;
}
.\!text-2xl{
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}
.\!text-base{
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.text-2xl{
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl{
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-\[12px\]{
  font-size: 12px;
}
.text-\[18px\]{
  font-size: 18px;
}
.text-\[56px\]{
  font-size: 56px;
}
.text-base{
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl{
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs{
  font-size: 0.75rem;
  line-height: 1rem;
}
.\!font-normal{
  font-weight: 400 !important;
}
.font-bold{
  font-weight: 700;
}
.font-medium{
  font-weight: 500;
}
.font-normal{
  font-weight: 400;
}
.uppercase{
  text-transform: uppercase;
}
.lowercase{
  text-transform: lowercase;
}
.italic{
  font-style: italic;
}
.leading-4{
  line-height: 1rem;
}
.\!text-gray-200{
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1)) !important;
}
.\!text-gray-600{
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1)) !important;
}
.\!text-scbluefrance{
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 145 / var(--tw-text-opacity, 1)) !important;
}
.\!text-slate-900{
  --tw-text-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1)) !important;
}
.\!text-white{
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}
.text-\[\#4B5874\]{
  --tw-text-opacity: 1;
  color: rgb(75 88 116 / var(--tw-text-opacity, 1));
}
.text-\[\#666666\]{
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity, 1));
}
.text-black{
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.text-gray-100{
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}
.text-gray-200{
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}
.text-gray-300{
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}
.text-gray-400{
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}
.text-gray-500{
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
.text-gray-600{
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}
.text-gray-700{
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}
.text-green-700{
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}
.text-green-900{
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}
.text-red-500{
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}
.text-red-600{
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}
.text-scbluefrance{
  --tw-text-opacity: 1;
  color: rgb(0 0 145 / var(--tw-text-opacity, 1));
}
.text-scblueinfo{
  --tw-text-opacity: 1;
  color: rgb(0 99 203 / var(--tw-text-opacity, 1));
}
.text-scgreensuccess{
  --tw-text-opacity: 1;
  color: rgb(22 117 60 / var(--tw-text-opacity, 1));
}
.text-schint{
  --tw-text-opacity: 1;
  color: rgb(101 101 101 / var(--tw-text-opacity, 1));
}
.text-sclightblue{
  --tw-text-opacity: 1;
  color: rgb(43 124 159 / var(--tw-text-opacity, 1));
}
.text-scorange{
  --tw-text-opacity: 1;
  color: rgb(234 144 1 / var(--tw-text-opacity, 1));
}
.text-scorangewarn{
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity, 1));
}
.text-scpurplepop{
  --tw-text-opacity: 1;
  color: rgb(106 106 244 / var(--tw-text-opacity, 1));
}
.text-screderror{
  --tw-text-opacity: 1;
  color: rgb(206 6 1 / var(--tw-text-opacity, 1));
}
.text-scredinputerror{
  --tw-text-opacity: 1;
  color: rgb(206 5 0 / var(--tw-text-opacity, 1));
}
.text-slate-500{
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}
.text-slate-600{
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}
.text-slate-800{
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}
.text-white{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.underline{
  text-decoration-line: underline;
}
.opacity-60{
  opacity: 0.6;
}
.\!shadow-none{
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md{
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-black{
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}
.shadow-gray-300{
  --tw-shadow-color: #d1d5db;
  --tw-shadow: var(--tw-shadow-colored);
}
.outline{
  outline-style: solid;
}
.filter{
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition{
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* 
from https://cssloaders.github.io/
*/
.sc-loader {
  border: 1px solid #7b7b7b;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: sc-loader-rotation 1s linear infinite;
}
.sc-loader-big {
  border: 4px solid #000091;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: sc-loader-rotation 1s linear infinite;
}

@keyframes sc-loader-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 
https://github.com/GouvernementFR/dsfr/issues/264
*/
.no_external_link_icon::after {
  display: none !important;
}

/*
DSFR sizes don't go high enough
https://www.systeme-de-design.gouv.fr/elements-d-interface/fondamentaux-techniques/icones/
*/
.sc-icon-xxl::before {
  --icon-size: 6rem !important;
}

/*
For the news articles, it would be nice if we can just
write raw HTML, without having to put tailwind classes
on every title, etc.
*/
.sc-article h2 {
  font-size: 1.5rem;
  line-height: 2rem;
}
.before\:pointer-events-none::before{
  content: var(--tw-content);
  pointer-events: none;
}
.before\:absolute::before{
  content: var(--tw-content);
  position: absolute;
}
.before\:bottom-0::before{
  content: var(--tw-content);
  bottom: 0px;
}
.before\:left-2::before{
  content: var(--tw-content);
  left: 0.5rem;
}
.before\:left-\[-15px\]::before{
  content: var(--tw-content);
  left: -15px;
}
.before\:left-\[-28px\]::before{
  content: var(--tw-content);
  left: -28px;
}
.before\:right-0::before{
  content: var(--tw-content);
  right: 0px;
}
.before\:top-0::before{
  content: var(--tw-content);
  top: 0px;
}
.before\:top-2::before{
  content: var(--tw-content);
  top: 0.5rem;
}
.before\:ml-12::before{
  content: var(--tw-content);
  margin-left: 3rem;
}
.before\:block::before{
  content: var(--tw-content);
  display: block;
}
.before\:\!hidden::before{
  content: var(--tw-content);
  display: none !important;
}
.before\:h-full::before{
  content: var(--tw-content);
  height: 100%;
}
.before\:w-\[1px\]::before{
  content: var(--tw-content);
  width: 1px;
}
.before\:border-0::before{
  content: var(--tw-content);
  border-width: 0px;
}
.before\:border-l-2::before{
  content: var(--tw-content);
  border-left-width: 2px;
}
.before\:border-solid::before{
  content: var(--tw-content);
  border-style: solid;
}
.before\:border-l-scredinputerror::before{
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(206 5 0 / var(--tw-border-opacity, 1));
}
.before\:bg-gray-500::before{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}
.before\:text-gray-600::before{
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}
.before\:content-\[\'\'\]::before{
  --tw-content: '';
  content: var(--tw-content);
}
.before\:content-\[\'SC-\'\]::before{
  --tw-content: 'SC-';
  content: var(--tw-content);
}
.before\:content-\[\'_\'\]::before{
  --tw-content: ' ';
  content: var(--tw-content);
}
.after\:\!hidden::after{
  content: var(--tw-content);
  display: none !important;
}
.hover\:border-scbluefrance:hover{
  --tw-border-opacity: 1;
  border-color: rgb(0 0 145 / var(--tw-border-opacity, 1));
}
.hover\:\!bg-gray-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1)) !important;
}
.hover\:bg-gray-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.hover\:text-blue-500:hover{
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}
.hover\:underline:hover{
  text-decoration-line: underline;
}
.focus\:outline-2:focus{
  outline-width: 2px;
}
.focus\:outline-blue-500:focus{
  outline-color: #3b82f6;
}
.group:hover .group-hover\:visible{
  visibility: visible;
}
.ui-active\:bg-gray-300[data-headlessui-state~="active"]{
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}
:where([data-headlessui-state~="active"]) .ui-active\:bg-gray-300{
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}
@media not all and (min-width: 768px){

  .max-md\:hidden{
    display: none;
  }
}
@media (min-width: 576px){

  .sm\:block{
    display: block;
  }

  .sm\:w-1\/2{
    width: 50%;
  }

  .sm\:max-w-4xl{
    max-width: 56rem;
  }

  .sm\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:flex-row{
    flex-direction: row;
  }

  .sm\:bg-transparent{
    background-color: transparent;
  }

  .sm\:bg-white{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }

  .sm\:px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 768px){

  .md\:right-5{
    right: 1.25rem;
  }

  .md\:block{
    display: block;
  }

  .md\:flex{
    display: flex;
  }

  .md\:hidden{
    display: none;
  }

  .md\:w-1\/2{
    width: 50%;
  }

  .md\:w-1\/3{
    width: 33.333333%;
  }

  .md\:w-2\/3{
    width: 66.666667%;
  }

  .md\:w-\[507px\]{
    width: 507px;
  }

  .md\:w-auto{
    width: auto;
  }

  .md\:w-fit{
    width: -moz-fit-content;
    width: fit-content;
  }

  .md\:max-w-\[420px\]{
    max-width: 420px;
  }

  .md\:list-none{
    list-style-type: none;
  }

  .md\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row{
    flex-direction: row;
  }

  .md\:flex-row-reverse{
    flex-direction: row-reverse;
  }

  .md\:flex-wrap{
    flex-wrap: wrap;
  }

  .md\:items-center{
    align-items: center;
  }

  .md\:justify-start{
    justify-content: flex-start;
  }

  .md\:justify-around{
    justify-content: space-around;
  }

  .md\:gap-4{
    gap: 1rem;
  }

  .md\:gap-8{
    gap: 2rem;
  }

  .md\:gap-y-4{
    row-gap: 1rem;
  }

  .md\:border-2{
    border-width: 2px;
  }

  .md\:pl-0{
    padding-left: 0px;
  }

  .md\:pl-4{
    padding-left: 1rem;
  }

  .md\:pr-3{
    padding-right: 0.75rem;
  }
}
@media (min-width: 992px){

  .lg\:bottom-5{
    bottom: 1.25rem;
  }

  .lg\:mb-0{
    margin-bottom: 0px;
  }

  .lg\:block{
    display: block;
  }

  .lg\:flex{
    display: flex;
  }

  .lg\:grid{
    display: grid;
  }

  .lg\:hidden{
    display: none;
  }

  .lg\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:flex-row{
    flex-direction: row;
  }
}
@media (min-width: 1280px){

  .xl\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:flex-row{
    flex-direction: row;
  }

  .xl\:text-base{
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

